<template>
  <div id="car">
    <Banner title="" sub="" image="banner-car.jpg" />
    <section class="uk-section uk-background-muted">
      <div class="uk-container uk-container-large">
        <div class="uk-margin-medium uk-text-center">
          <h3 class="uk-margin-remove">汽車貸款</h3>
          <hr class="uk-divider-small uk-margin-small" />
          <h3 class="uk-margin-remove">八大優勢</h3>
        </div>
        <div class="uk-margin">
          <div class="uk-grid-large uk-child-width-1-4@m" uk-grid>
            <div v-for="(item, index) in MAINitems" :key="index" class="uk-text-center">
              <img width="144" v-lazy="require(`@/assets/car/${index}.png`)" />
              <h5 class="uk-margin uk-text-center">{{ item.title }}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="uk-section">
      <div class="uk-container uk-container-large">
        <div class="uk-margin-medium uk-text-center">
          <h3 class="uk-margin-remove">申請條件</h3>
          <hr class="uk-divider-small uk-margin-small" />
        </div>
        <div class="uk-flex-center uk-flex uk-margin">
          <div class="uk-width-auto@m">
            <ul class="uk-list uk-list-decimal">
              <li>信用卡持有滿一年且近半年無延遲紀錄 (無消費紀錄不在此限)。</li>
              <li>
                現職滿一年以上，提供投保證明(異動)、薪轉近三個月存摺影本。
              </li>
              <li>申請人或保證人持有不動產滿半年以上。</li>
              <li>
                保人為直系或旁系三等親且在職/保人正職一年以上(附勞保或薪轉)。
              </li>
              <li>
                車主或保人為本行既有客戶或他行客戶繳滿12期以上且近半年內無逾期繳款紀錄。
              </li>
              <li>車主或保人為裕隆或他行"繳款期滿"客戶，且無誇張逾期紀錄。</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="uk-child-width-1-2@m uk-grid-collapse" uk-grid>
        <div class="uk-background-muted uk-padding-large">
          <div class="uk-margin-medium uk-text-center">
            <h3 class="uk-margin-remove">產品資訊</h3>
            <hr class="uk-divider-small uk-margin-small" />
          </div>
          <div class="uk-flex-center uk-flex uk-margin">
            <ul class="uk-list uk-nav">
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >信用產品類別:
                </label>
                原車融資, 借新還舊, 代償銀行, 中古車
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >貸放期限(年):
                </label>
                車齡+貸款期限&lt;=19年 (依照各專案標準)
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >成數上限:
                </label>
                130~180%
              </li>

              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >額度(萬):
                </label>
                10萬~200萬內 (依照各類專案標準)
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary">期數: </label>
                12-60期內 (依照各類專案標準)
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >承作利率:
                </label>
                依各專案
              </li>
            </ul>
          </div>
        </div>
        <div class="uk-background-primary uk-padding-large uk-light">
          <div class="uk-margin-medium uk-text-center">
            <h3 class="uk-margin-remove">準備資料</h3>
            <hr class="uk-divider-small uk-margin-small" />
          </div>
          <div class="uk-flex-center uk-flex uk-margin">
            <ul class="uk-list uk-list-disc">
              <li>申請人 雙證件正反面</li>
              <li>勞保異動</li>
              <li>汽車行照</li>
              <li>申請人存摺封面</li>
              <li>存摺補登最新之近半年內頁</li>
              <li>保人雙證件正反面, 存摺封面 ,存摺補登最新之近半年內頁</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Faq :items="QAitems" />
    <section
      class="uk-section uk-background-cover"
      v-bind:style="{ 'background-image': 'url(' + JoinImage + ')' }"
    >
      <div class="uk-container uk-container-large">
        <Join type="C1" />
      </div>
    </section>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import Join from '@/components/Join.vue'
import Faq from '@/components/Faq.vue'
export default {
  name: 'Car',
  metaInfo: {
    title: '汽車',
  },
  components: {
    Banner,
    Join,
    Faq,
  },
  data() {
    return {
      JoinImage: require('@/assets/banner-contact.jpg'),
      MAINitems: [
        {
          title: '手續簡單',
          sub: '',
        },
        {
          title: '免費服務',
          sub: '',
        },
        {
          title: '利率很低',
          sub: '',
        },
        {
          title: '條件寬鬆',
          sub: '',
        },
         {
          title: '撥款神速',
          sub: '',
        },
        {
          title: '還款輕鬆',
          sub: '',
        },
        {
          title: '額度超高',
          sub: '',
        },
        {
          title: '繳錢便利',
          sub: '',
        },
      ],
      QAitems: [
        {
          question: '容昇貸款顧問-車貸會收哪些費用？',
          answer:
            '估價和申請過程無須任何費用，僅在對保時需繳交一筆動保設定費NTD 3500元，全台汽車貸款皆會收此費用。',
        },
        {
          question: '車貸年利率、額度多少？',
          answer:
            '我們「年利率」7-14%、最高可貸350萬，依照個人情況為您量身打造，採本息攤還，讓您繳款無壓力。',
        },
        {
          question: '容昇貸款顧問-車貸審核需多久時間？核准後多久撥款？',
          answer:
            '最快當日可核准，核准後，最快一個工作天可撥款，火速解決您的資金需求。',
        },
        {
          question: '我的車還有貸款，能幫我代償且提高額度嗎？',
          answer:
            '可以，我們會將您的前貸代償結清，並幫您爭取車價最高200%的高額度與降息優惠，讓您有充裕的現金可運用。',
        },
        {
          question: '申請車貸後，車子可以繼續使用嗎？',
          answer:
            '免留車！您的汽車貸款核準後，愛車無需交由銀行保管，一樣可自由使用，我們會向監理單位申請設定您的汽車債權，在車貸未結清之前，無法買賣跟過戶。',
        },
        {
          question: '曾有信用瑕疵、協商是否可以申請車貸？',
          answer:
            '基本上無法，但如果符合以下情況仍可申貸<br/>●信用瑕疵只要您恢復正常滿一年<br/>●協商中繳款正常也有機會過件',
        },
        {
          question: '車貸如何選擇呢?',
          answer:
            '找融資公司直屬經銷商 並且有實體辦公室而不是網路上只有留電話或者line甚至找誰辦車貸都不知道!<br/>找容昇貸款顧問準沒錯!',
        },
        {
          question: '以上都沒有我的問題，怎麼辦？',
          answer: '手機可直撥0917-601-688 黃副理 或 加LINE詢問',
        },
      ],
    }
  },
}
</script>

 