<template>
  <div id="offcanvas-nav" uk-offcanvas="overlay: true">
    <div class="uk-offcanvas-bar uk-background-primary">
      <ul class="uk-nav uk-nav-default" @click="offcanvasClose">
        <li>
          <router-link to="/">首頁</router-link>
        </li>
        <li>
          <router-link to="/about">關於我們</router-link>
        </li>
        <li>
          <router-link to="/contact">聯絡我們</router-link>
        </li>
        <li class="uk-nav-divider"></li>
        <li class="uk-nav-header">服務項目</li>
        <li>
          <router-link to="/service/house">房屋</router-link>
        </li>
        <li>
          <router-link to="/service/car">汽車</router-link>
        </li>
        <li>
          <router-link to="/service/motor">機車</router-link>
        </li>
        <li>
          <router-link to="/service/allborrow">萬物貸</router-link>
        </li>
        <li class="uk-nav-divider uk-margin-top"></li>
        <li class="uk-nav-header">聯絡資訊</li>
        <li>
          <!-- <a href="tel:03-4595055"
            ><span
              class="uk-margin-small-right"
              uk-icon="icon: receiver"
            ></span>
            03-4595055</a
          > -->
          <a href="tel:0917-601-688"
            ><span
              class="uk-margin-small-right"
              uk-icon="icon: receiver"
            ></span>
            0917-601-688 黃副理</a
          >
        </li>
        <li>
          <a href="#">
            <span class="uk-margin-small-right" uk-icon="icon: print"></span>
            03-4581055
          </a>
        </li>
        <li>
          <a href="https://goo.gl/maps/Yv1PzFCdUqWdjBgA7" target="_blank"
            ><span
              class="uk-margin-small-right"
              uk-icon="icon: location"
            ></span>
            桃園市平鎮區金陵路298號1樓</a
          >
        </li>
        <li class="uk-nav-divider uk-margin-top"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
export default {
  methods: {
    offcanvasClose() {
      UIkit.offcanvas('#offcanvas-nav').hide()
    },
  },
}
</script>