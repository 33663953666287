<template>
  <div id="home">
    <Slideshow />
    <section>
      <div class="uk-container uk-container-large">
        <Join type="0" />
      </div>
    </section>
    <Service />
    <section class="uk-section uk-section-muted tm-section-primaryBackground">
      <div class="uk-margin-medium uk-text-center">
        <h3 class="uk-margin-remove">關於容昇</h3>
        <hr class="uk-divider-small uk-margin-small" />
      </div>
      <div class="uk-container uk-container-small">
        <div class="uk-child-width-1-2@m" uk-grid>
          <div class="uk-flex uk-flex-middle">
            <img src="@/assets/about-img.jpg" />
          </div>
          <div class="uk-inline">
            <h4>20年以上的銀行專業資歷團隊</h4>
            <p>
              容昇貸款顧問是代理各大銀行及融資公司的理財顧問公司，非坊間代辦公司，案件過件前後皆無收取任何開辦費、代辦費，以銀行體系出身的顧問，為公司基本組織架構，提供專業迅速的高品質服務，成為您財務上理財規劃師。
              我們的使命在於透過我們獨特銀行專業度，結合各產業的專業知識，以關懷客戶為起點，從宏觀的格局整合財務及信用管理，為您設計專屬的全方位財務規劃！<br />
            </p>
            <router-link class="uk-button uk-button-primary" to="/about"
              >MORE</router-link
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Slideshow from '@/components/Slideshow.vue'
import Join from '@/components/Join.vue'
import Service from '@/components/Service.vue'


export default {
  name: 'Home',
  components: {
    Slideshow,
    Join,
    Service,
  },
  metaInfo: {
    title: '首頁',
  },
}
</script>

