<template>
  <div
    id="loading"
    v-if="isLoading"
    v-bind:class="{
      'uk-animation-fade uk-animation-reverse': !isLoadingAddClass,
    }"
  >
    <div class="uk-overlay uk-position-center uk-text-center tm-load">
      <div class="uk-text-secondary" uk-spinner></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      isLoadingAddClass: true,
    };
  },
  beforeMount() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    this.isLoadingAddClass = false;
  },
};
</script>

<style>
#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  text-align: center;
}

</style>
