<template>
  <div id="house">
    <Banner title="" sub="" image="banner-house.jpg" />
    <section class="uk-section uk-background-muted">
      <div class="uk-container uk-container-large">
        <div class="uk-margin-medium uk-text-center">
          <h3 class="uk-margin-remove">房屋貸款</h3>
          <hr class="uk-divider-small uk-margin-small" />
          <h3 class="uk-margin-remove">六大優勢</h3>
        </div>
        <div class="uk-margin">
          <div class="uk-grid-large uk-child-width-1-3@m" uk-grid>
            <div
              v-for="(item, index) in MAINitems"
              :key="index"
              class="uk-text-center"
            >
              <img
                width="144"
                v-lazy="require(`@/assets/house/${index}.png`)"
              />
              <h5 class="uk-margin uk-text-center">{{ item.title }}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="uk-margin-large-top">
      <div class="uk-background-default" uk-sticky="offset: 80">
        <div class="uk-container uk-container-large">
          <ul
            class="uk-child-width-expand@m uk-child-width-1-2"
            uk-grid
            uk-tab="connect: #component-nav"
          >
            <li v-for="(item, index) in items" :key="index">
              <a href="#">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
        <hr class="uk-margin-remove" />
      </div>
    </div>
    <ul id="component-nav" class="uk-switcher">
      <li v-for="(item, index) in items" :key="index">
        <section
          class="uk-section uk-background-muted"
          v-if="item.term || item.termSend"
        >
          <div class="uk-container uk-container-large">
            <div
              class="uk-flex-center uk-child-width-1-2@m uk-child-width-1-3@l"
              uk-grid
              uk-height-match="target: > div > .uk-card"
            >
              <div v-if="item.termSend">
                <div class="uk-card uk-card-default uk-card-body">
                  <div class="uk-margin-medium uk-text-center">
                    <h3 class="uk-margin-remove">申請條件</h3>
                    <hr class="uk-divider-small uk-margin-small" />
                  </div>
                  <div class="uk-flex-center uk-flex uk-margin">
                    <div class="uk-width-auto@m">
                      <ul class="uk-list uk-list-decimal">
                        <li
                          v-for="(item_, index) in item.termSend"
                          :key="index"
                        >
                          {{ item_ }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.term">
                <div class="uk-card uk-card-default uk-card-body">
                  <div class="uk-margin-medium uk-text-center">
                    <h3 class="uk-margin-remove">限制條件</h3>
                    <hr class="uk-divider-small uk-margin-small danger" />
                  </div>
                  <div class="uk-flex-center uk-flex uk-margin">
                    <div class="uk-width-auto@m">
                      <ul class="uk-list uk-list-decimal">
                        <li v-for="(item_, index) in item.term" :key="index">
                          {{ item_ }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="uk-section" v-if="item.info">
          <div class="uk-margin-medium uk-text-center">
            <h3 class="uk-margin-remove">產品資訊</h3>
            <hr class="uk-divider-small uk-margin-small" />
          </div>
          <div class="uk-flex-center uk-flex uk-margin uk-text-center">
            <ul class="uk-list uk-nav">
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >成數上限:
                </label>
                {{ item.limit }}
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >額度(萬):
                </label>
                {{ item.quota }}
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary">期數: </label>
                {{ item.staging }}
              </li>
              <hr class="uk-divider" />
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >貸款金額:
                </label>
                {{ item.amount }}
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary">50萬: </label>
                {{ item.amount_50 }}
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary">100萬: </label>
                {{ item.amount_100 }}
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary">200萬: </label>
                {{ item.amount_200 }}
              </li>
            </ul>
          </div>
          <p class="uk-text-center uk-text-bolder uk-text-secondary">
            ※申辦利息金額，需以客戶條件做調整。
          </p>
        </section>
        <section class="uk-section" v-if="item.other">
          <div class="uk-margin-medium uk-text-center">
            <h3 class="uk-margin-remove">產品特色</h3>
            <hr class="uk-divider-small uk-margin-small" />
          </div>
          <div class="uk-flex-center uk-flex uk-margin uk-text-center">
            <ul class="uk-list uk-nav">
              <li v-for="(item_, index) in item.other" :key="index">
                <label
                  v-if="item_.title"
                  class="uk-text-bolder uk-text-secondary"
                  >{{ item_.title }}
                </label>
                <span v-html="item_.desc">
                  {{ item_.desc }}
                </span>
              </li>
            </ul>
          </div>
        </section>
      </li>
    </ul>
    <section class="uk-section uk-background-primary uk-light">
      <div class="uk-margin-medium uk-text-center">
        <h3 class="uk-margin-remove">申請所需資料</h3>
        <hr class="uk-divider-small uk-margin-small" />
      </div>
      <div class="uk-flex-center uk-flex uk-margin">
        <ul class="uk-list uk-list-decimal">
          <li>
            借款人、擔保品提供人暨連保人身分證+第二身份證件。(身分證+健保卡或駕照)
          </li>
          <li>借款人、擔保品提供人暨連保人印章及印鑑證明2份。</li>
          <li>擔保品前順位銀行房貸近半年繳息明細。</li>
          <li>
            擔保品房屋不動產資料。(土地、建物權狀正本及謄本、建物平面圖、地籍圖)
          </li>
          <li>借款人名下車輛資料。(行照)</li>
          <li>借款人工作收入、財力證明。(無營登自營商需檢附經營場所之照片)</li>
          <li>
            借款人聯合徵信中心-信用報告書。(可用自然人憑證上網查詢；公司負責人需再提供企業的徵信報告)
          </li>
          <li>擔保品房屋照片。(門牌、外觀、臨路、出入口、室內)</li>
        </ul>
      </div>
    </section>
    <Faq :items="QAitems" />
    <section
      class="uk-section uk-background-cover"
      v-bind:style="{ 'background-image': 'url(' + JoinImage + ')' }"
    >
      <div class="uk-container uk-container-large">
        <Join type="C3" />
      </div>
    </section>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import Join from '@/components/Join.vue'
import Faq from '@/components/Faq.vue'

export default {
  name: 'House',
  metaInfo: {
    title: '房屋',
  },
  components: {
    Banner,
    Join,
    Faq,
  },
  data() {
    return {
      JoinImage: require('@/assets/banner-contact.jpg'),
      MAINitems: [
        {
          title: '全省服務 - 離島地區除外',
          sub: '',
        },
        {
          title: '銀行合法利息',
          sub: '',
        },
        {
          title: '輕鬆還款 - 本利均攤',
          sub: '',
        },
        {
          title: '最高可貸 - 房價110%',
          sub: '',
        },
        {
          title: '不限職業 - 領現也可貸',
          sub: '',
        },
        {
          title: '信用不良協商皆可辦理',
          sub: '',
        },
      ],
      /**
       * Level
       * @constructor
       * @param {string} name - 專案名稱.
       * @param {true,false} info - 產品資訊.
       * @param {string} limit - 成數上限.
       * @param {string} quota - 額度.
       * @param {string} staging - 期數.
       * @param {string} term - 條件.
       * @param {string} amount - 金額.
       * @param {string} amount_50 - 金額50萬.
       * @param {string} amount_100 - 金額100萬.
       * @param {string} amount_200 - 金額200萬.
       * @param {string} termSend - 申請條件.
       *@param {string} other - 其他.
       */
      items: [
        {
          name: '民間代償專案',
          info: false,
          limit: '',
          quota: '',
          staging: '',
          term: '',
          amount: '',
          amount_50: '',
          amount_100: '',
          amount_200: '',
          termSend: '',
          other: [
            {
              title: '',
              desc: "<b class='uk-text-large uk-text-emphasis'>無特別申請條件及限制條件</b>",
            },
            {
              title: '',
              desc: '不動產（私設/持分）可申請年利率最低8.8%，最長可分12年',
            },
            {
              title: '房貸遲繳：',
              desc: '可承作',
            },
            {
              title: '無明確收入：',
              desc: '可承作',
            },
            {
              title: '私人設定：',
              desc: '可承作',
            },
            {
              title: '協商註記：',
              desc: '可承作',
            },
            {
              title: '假扣押/查封：',
              desc: '可承作',
            },
            {
              title: '呆帳/催收：',
              desc: '可承作',
            },
          ],
        },
        {
          name: 'A級客戶',
          info: true,
          limit: '110%',
          quota: '10萬~300萬',
          staging: '24期~120期',
          term: [
            '貸款綁約20期。',
            '持有一年以下房屋不承作。',
            '借款人年齡22~65歲，申請人需為上班族或自營商(無營登可)。',
            '需設定不動產第二順位。',
          ],
          amount: '87期7年 每月利息',
          amount_50: '3421元',
          amount_100: '6844元',
          amount_200: '13688元',
          termSend: [
            '一年內名下貸款信用卡繳款正常',
            '名下無信貸(無擔保貸款)。',
            '房屋持有5年以上。',
          ],
        },
        {
          name: 'B級客戶',
          info: true,
          limit: '100%',
          quota: '10萬~300萬',
          staging: '24期~120期',
          term: [
            '貸款綁約20期。',
            '持有一年以下房屋不承作。',
            '借款人年齡22~65歲，申請人需為上班族或自營商(無營登可)。',
            '需設定不動產第二順位。',
          ],
          amount: '120期10年 每月利息',
          amount_50: '3302元',
          amount_100: '6604元',
          amount_200: '13208元',
          termSend: [
            '一年內名下貸款信用卡繳款正常',
            '信用卡循環動用比例小於50%。',
            '房屋持有滿2年以上。',
          ],
        },
        {
          name: 'C級客戶',
          info: true,
          limit: '90%',
          quota: '10萬~300萬',
          staging: '24期~120期',
          term: [
            '貸款綁約20期。',
            '持有一年以下房屋不承作。',
            '借款人年齡22~65歲，申請人需為上班族或自營商(無營登可)。',
            '需設定不動產第二順位。',
          ],
          amount: '1年利息總支出',
          amount_50: '約41052元',
          amount_100: '約82128元',
          amount_200: '約164256元',
          termSend: [
            '一年內名下貸款信用卡繳款正常',
            '名下無信貸(無擔保貸款)。',
            '房屋持有5年以上。',
          ],
        },
      ],
      QAitems: [
        {
          question: '二胎房貸有哪些選擇？',
          answer:
            '1.銀行：最好的選擇就是「銀行」，但因為銀行的二胎房貸審核嚴格(被銀行法限制)，被婉拒的機率都比過件率高。<br><br>2.裕融二胎：銀行婉拒後可優先考慮，同為股票上市的「裕融企業」，審核標準寬鬆，過件率高、貸款成數高、額度高，最快3天可以撥款。<br><br>3.民間代書：代書、當鋪等通路都能借二胎房貸，但利率很高，也會加收高額費用，非常不推薦。',
        },
        {
          question: '什麼時候可以考慮裕融企業附擔保貸款(二胎房貸)？',
          answer:
            '原本的銀行房屋增貸被婉拒、向別家銀行申請轉增貸也婉拒，明明房子就還有殘值空間，卻貸不出來，其實這時候就可以考慮裕融二胎房貸，把房屋剩餘可貸的空間借出來。',
        },
        {
          question: '裕融企業房屋抵押貸款的正式名稱是什麼？',
          answer:
            '裕融二胎(裕融二胎)是由裕融企業旗下的裕融(股)公司推出的貸款方案(分期付款)，正式名稱為「分期付款之不動產二胎附擔保業務」，礙於法規，客戶不能直接找裕融公司申貸，必須透過經銷商辦理。',
        },
        {
          question: '裕融附擔保貸款(二胎房貸)跟銀行有什麼不一樣？',
          answer:
            '僅是撥款單位的不同，裕融企業和銀行一樣都是股票上市公司，財務狀況穩健，因不受銀行法限制、金管會管轄，審核標準也比較寬鬆，比起銀行過件機率高很多。',
        },
        {
          question: '裕融企業附擔保貸款(二胎房貸)跟民間二胎有什麼不一樣？',
          answer:
            '裕融二胎房貸不同於一般民間二胎，我們的作法跟銀行一模一樣，但審核速度快，且準備資料比銀行少很多，也不用本人親臨辦理，和銀行一樣作本息攤還，不像一般民間二胎只繳息不還本，找容昇貸款顧問辦理，更不需先簽委託書或繳交代辦服務費。',
        },
        {
          question: '裕融企業附擔保貸款(二胎房貸)的流程？',
          answer:
            '線上填單/電話/LINE聯絡 ➔ 評估審核 ➔ 核准對保(至地政對保並送件設定 )➔ 2天後撥款，欲知詳情可參考裕融二胎房貸流程',
        },
        {
          question:
            '容昇貸款顧問房屋二胎貸款有高額的手續費、服務費、代辦費嗎？',
          answer:
            '沒有喔！估價和申請過程無須任何費用，僅在對保時繳交對保設定相關費用（包含代書、地政規費等）全省二胎房貸皆需繳交此費用，想了解詳情可免費諮詢',
        },
        {
          question:
            '為什麼辦裕融附擔保貸款(二胎房貸)要找經銷商？我可以直接找裕融嗎？',
          answer:
            '礙於法規關係，裕融不能直接對客戶放款，需透過簽約經銷商轉介，所以找到正式簽約的經銷商非常重要，容昇貸款顧問就是正式簽約的經銷商，有正式的合約書為證。',
        },
        {
          question: '裕融企業附擔保貸款(二胎房貸)經銷商選在地的比較好嗎？',
          answer:
            '裕融二胎房貸經銷商全省只有5家，也就是說要申請裕融二胎房貸，必須找這5家其中一家送件申請才行，不一定要找住家附近的經銷商，過件率高的經銷商會決定你的案件是否核貸，或是核貸金額的高低，所以選對經銷商非常重要。',
        },
        {
          question:
            '我的房子有私人設定紀錄，可以辦裕融企業附擔保(二胎房貸)嗎？',
          answer:
            '私人設定紀錄塗銷滿半年，就可以申辦裕融二胎房貸，目前有私人設定，請來電由業務經理為您評估。',
        },
        {
          question: '我有債務協商紀錄，可以申請裕融附擔保貸款(二胎房貸)嗎？',
          answer:
            '債務協商正常還款滿3年即可申貸，還款未滿3年者，提供信用正常的人當借款人，即可申貸裕融二胎房貸。',
        },
        {
          question: '我有信用不良紀錄，可以申請裕融附擔保貸款(二胎房貸)嗎？',
          answer:
            '因積欠銀行款項導致信用不良紀錄，該欠款繳清後即可申請裕融二胎房貸，不用等到信用恢復正常。',
        },
        {
          question:
            '自己沒有房子，還可以找裕融裕融申請附擔保貸款(二胎房貸)嗎？',
          answer:
            '自己名下如果沒有房子，可以提供親戚、朋友的房子作為擔保，並由親友擔任保證人，這樣還是可以申請裕融裕融的二胎房貸。',
        },
        {
          question: '以上都找不到我的問題，怎麼辦？',
          answer:
            '您可以：<br>1. 免費填單，請專人跟我聯絡<br>2. 用LINE與業務經理聯絡<br><br>我們的服務範圍為：<br>台北市、新北市、基隆市、桃園市、新竹縣、新竹市、宜蘭縣、苗栗縣、台中市、彰化縣、南投縣、雲林縣、嘉義縣、嘉義市、台南市、高雄市、屏東縣、花蓮縣、台東縣。',
        },
      ],
    }
  },
}
</script>

<style>
#house .uk-tab > * > a {
  font-size: 20px;
}
</style>