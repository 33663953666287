<template>
  <div id="allborrow">
    <Banner title="" sub="" image="banner-all.jpg" />
    <div class="uk-margin-large-top">
      <div class="uk-background-default" uk-sticky="offset: 80">
        <div class="uk-container uk-container-large">
          <ul
            class="uk-child-width-expand uk-text-center"
            uk-grid
            uk-tab="connect: #component-nav"
            uk-height-match="target: > li > a"
          >
            <li v-for="(item, index) in items" :key="index">
              <a v-html="item.name" href="#">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
        <hr class="uk-margin-remove" />
      </div>
    </div>
    <ul id="component-nav" class="uk-switcher">
      <li v-for="(item, index) in items" :key="index">
        <section class="uk-section uk-background-muted">
          <div class="uk-container uk-container-large">
            <div
              class="uk-flex-center uk-child-width-1-2@m uk-child-width-1-3@l"
              uk-grid
              uk-height-match="target: > div > .uk-card"
            >
              <div>
                <div class="uk-card uk-card-default uk-card-body">
                  <div class="uk-margin-medium uk-text-center">
                    <h3 class="uk-margin-remove">申請條件</h3>
                    <hr class="uk-divider-small uk-margin-small" />
                  </div>
                  <div class="uk-flex-center uk-flex uk-margin">
                    <div class="uk-width-auto@m">
                      <ul class="uk-list uk-list-decimal">
                        <li
                          v-for="(item_, index) in item.termSend"
                          :key="index"
                          v-html="item_"
                        >
                          {{ item_ }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body">
                  <div class="uk-margin-medium uk-text-center">
                    <h3 v-if="item.Seven">{{ item.Seven }}</h3>
                    <h3 v-if="!item.Seven" class="uk-margin-remove">
                      絕不承做條件
                    </h3>
                    <hr class="uk-divider-small uk-margin-small danger" />
                  </div>
                  <div class="uk-flex-center uk-flex uk-margin">
                    <div class="uk-width-auto@m">
                      <ul class="uk-list uk-list-decimal">
                        <li v-for="(item_, index) in item.term" :key="index">
                          {{ item_ }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="uk-child-width-1-2@m uk-grid-collapse" uk-grid>
            <div class="uk-background-default uk-padding-large">
              <div class="uk-margin-medium uk-text-center">
                <h3 class="uk-margin-remove">產品資訊</h3>
                <hr class="uk-divider-small uk-margin-small" />
              </div>
              <div class="uk-flex-center uk-flex uk-margin">
                <ul class="uk-list uk-nav">
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >額度(萬):
                    </label>
                    {{ item.quota }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >期數:
                    </label>
                    {{ item.staging }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >手續費:
                    </label>
                    {{ item.handfee }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >違約金:
                    </label>
                    {{ item.breakfee }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >專案利率:
                    </label>
                    {{ item.interest }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >客戶利率:
                    </label>
                    {{ item.interestCustom }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >起佣利率(基數表):
                    </label>
                    {{ item.commissionStart }}
                  </li>
                  <li>
                    <label class="uk-text-bolder uk-text-secondary"
                      >聯絡人:
                    </label>
                    必填
                  </li>
                </ul>
              </div>
            </div>
            <div class="uk-background-primary uk-padding-large uk-light">
              <div class="uk-margin-medium uk-text-center">
                <h3 class="uk-margin-remove">申請所需資料</h3>
                <hr class="uk-divider-small uk-margin-small" />
              </div>
              <div class="uk-flex-center uk-flex uk-margin">
                <ul class="uk-list uk-list-disc">
                  <li>申請書</li>
                  <li>身分證正反面</li>
                  <li>撥款存摺封面</li>
                  <li>符合客戶條件</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </li>
    </ul>
    <section
      class="uk-section uk-background-cover"
      v-bind:style="{ 'background-image': 'url(' + JoinImage + ')' }"
    >
      <div class="uk-container uk-container-large">
        <Join type="C4" />
      </div>
    </section>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Join from "@/components/Join.vue";

export default {
  name: "AllBorrow",
  metaInfo: {
    title: "萬物貸",
  },
  components: {
    Banner,
    Join,
  },
  data() {
    return {
      JoinImage: require("@/assets/banner-contact.jpg"),
      /**
       * Level
       * @constructor
       * @param {string} name - 專案名稱.
       * @param {string} quota - 額度.
       * @param {string} staging - 期數.
       * @param {string} handfee - 手續費.
       * @param {string} breakfee - 違約金.
       * @param {string} interest - 專案利率.
       * @param {string} interestCustom - 客戶利率.
       * @param {string} commissionStart - 起佣利率.
       * @param {string} must - 必備條件.
       * @param {string} mustCustom - 客戶條件.
       * @param {string} term - 條件.
       * @param {string} termSend - 申請條件.
       *
       */
      items: [
        {
          name: "輕鬆付",
          quota: "額度 10 萬",
          staging: "12~24 期",
          handfee: "本金 + 4%",
          breakfee: "剩餘分期總額 * 20%",
          interest: "16%",
          interestCustom: "16%~20%",
          commissionStart: "17%起",
          must: "限20~40歲之客戶申辦",
          mustCustom: "正職滿三個月(附勞保卡或薪轉存摺，不含公會投保)",
          term: [
            "TAC/裕富黑名單",
            "明下車輛/RE 私設",
            "照會異常",
            "TAC/裕富 呆帳戶、催收戶",
            "同業照會異常、呆帳戶、催收戶",
            "法學有不良註記",
            "非本國人",
            "半年內同業查詢 (不收二手件)",
          ],
          termSend: [
            "正職滿3個月<br>(附勞保卡或薪轉存摺，不含公會投保)",
            "出生年齡限制<br>1970/12/31~2000/7/1 (20~40歲)",
          ],
        },
        {
          name: "商品原融<br class='uk-hidden@s'>【一般專案】",
          quota: "額度 10 萬",
          staging: "12~24 期",
          handfee: "本金 + 4%",
          breakfee: "剩餘分期總額 * 20%",
          interest: "18%",
          interestCustom: "18%~20%",
          commissionStart: "18%",
          must: "無",
          mustCustom: "無",
          term: [
            "AC/裕富黑名單",
            "明下車輛/RE 私設",
            "照會異常",
            "TAC/裕富 呆帳戶、催收戶",
            "同業照會異常、呆帳戶、催收戶",
            "法學有不良註記",
            "非本國人",
          ],
          termSend: ["無"],
        },
        {
          name: "商品原融<br class='uk-hidden@s'>【優質專案】",
          quota: "額度 20 萬",
          staging: "12~42 期",
          handfee: "本金 + 4%",
          breakfee: "剩餘分期總額 * 20%",
          interest: "15%",
          interestCustom: "15%~20%",
          commissionStart: "15%",
          must: "半年內無同業查詢紀錄(含裕富)",
          mustCustom: "其中一項優質條件",
          term: [
            "正職滿 6 個月或軍公教 (附勞保卡或薪轉存摺，不含公會投保)",
            "申請人或保人自有不動產 (無銀行外私設，申+保 需持份全部)",
            "二等親屬保【該親屬保限65歲(含)以下】或正職保人",
            "提供近 15 天內(含)個人 JCIC (聯徵信用正常，不含信用空白)",
            "信用卡正常無逾期(提供近一期帳單)",
            "提供申請人同業或銀行貸款近半年正常繳息明細",
            "既有客戶 (正常繳滿 6 期以上)",
          ],
          termSend: [
            "半年內無同業查詢紀錄(含裕富)",
            "加上右方其中一項優質條件",
          ],
          Seven: "【七項優質條件】",
        },
      ],
    };
  },
};
</script>

<style>
#allborrow .uk-tab > * > a {
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 765px) {
  #allborrow .uk-tab > * > a {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  #allborrow .uk-tab > * > a {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  #allborrow .uk-tab > * > a {
    font-size: 13px;
  }
}
</style>