<template>
  <div id="contact">
    <Banner title="聯絡我們" sub="Contact" image="banner-contact.jpg" />
    <section class="uk-section">
      <div class="uk-container uk-container-large">
        <div class="uk-card uk-card-body uk-background-muted">
          <div class="uk-flex uk-flex-center">
            <div class="uk-width-3-5@m uk-width-1-1">
              <div class="uk-grid-small" uk-grid>
                <div class="uk-width-1-2@s uk-width-1-1">
                  <a class="uk-link-reset" href="tel: 0917-601-688">
                    <div class="uk-card uk-card-default uk-card-hover">
                      <div class="uk-card-body uk-text-center">
                        <span
                          class="uk-position-top-left uk-padding-small"
                          uk-icon="icon: phone; ratio: 1.2;"
                        ></span>
                        <h3 class="uk-margin-small">0917-601-688 </h3>
                        <h5 class="uk-margin-remove uk-text-meta">
                          黃副理
                          <!-- 電話 <small>Tel</small> -->
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="uk-width-1-2@s uk-width-1-1">
                  <a class="uk-link-reset" href="tel: 03-4581055">
                    <div class="uk-card uk-card-default uk-card-hover">
                      <div class="uk-card-body uk-text-center">
                        <span
                          class="uk-position-top-left uk-padding-small"
                          uk-icon="icon: print; ratio: 1.2;"
                        ></span>
                        <h3 class="uk-margin-small">03-4581055</h3>
                        <h5 class="uk-margin-remove uk-text-meta">
                          傳真 <small>Fax</small>
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-card uk-card-default uk-card-hover">
                    <div class="uk-card-body uk-text-center">
                      <span
                        class="uk-position-top-left uk-padding-small"
                        uk-icon="icon: location; ratio: 1.2;"
                      ></span>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2085.013817270828!2d121.2216078297626!3d24.94364092420804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34682253d85a7c9b%3A0xbe995ae73185b65b!2zMzI05qGD5ZyS5biC5bmz6Y6u5Y2A6YeR6Zm16LevMjk46Jmf!5e0!3m2!1szh-TW!2stw!4v1625842105916!5m2!1szh-TW!2stw"
                        width="100%"
                        height="300"
                        style="border: 0; border-radius: 10px"
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                      <h3 class="uk-margin-small">桃園市平鎮區金陵路298號1樓</h3>
                      <h5 class="uk-margin-remove uk-text-meta">
                        地址 <small>Address</small>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            uk-card uk-card-body uk-background-muted uk-flex uk-flex-center
          "
        >
          <form class="uk-form-stacked uk-width-3-5@m uk-width-1-1 tm-form">
            <div class="uk-margin uk-text-center">
              <h5 class="uk-text-muted">
                若有需求，請致電或填寫表單聯繫，我們將盡速為您服務!
              </h5>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text"
                >您的大名<small class="uk-text-danger">*</small></label
              >
              <div class="uk-form-controls">
                <input
                  placeholder="Name.."
                  class="uk-input"
                  type="text"
                  id="name"
                  name="name"
                  v-model="formName.value"
                  v-bind:class="{ 'uk-form-danger': formName.error }"
                  @focus="formName.error = false"
                />
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text"
                >您的電話<small class="uk-text-danger">*</small></label
              >
              <div class="uk-form-controls">
                <input
                  placeholder="Tel.."
                  class="uk-input"
                  type="tel"
                  id="phone"
                  name="phone"
                  v-model="formPhone.value"
                  v-bind:class="{ 'uk-form-danger': formPhone.error }"
                  @focus="formPhone.error = false"
                />
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text"
                >您的電子信箱</label
              >
              <div class="uk-form-controls">
                <input
                  class="uk-input"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email.."
                  v-model="formEmail.value"
                  v-bind:class="{ 'uk-form-danger': formEmail.error }"
                  @focus="formEmail.error = false"
                />
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text"
                >申請資訊</label
              >
              <div class="uk-grid-small uk-child-width-1-2@m" uk-grid>
                <div>
                  <div class="uk-form-controls">
                    <select
                      class="uk-select"
                      id="amount"
                      name="amount"
                      v-model="formAmount.value"
                    >
                      <option value="0">融資金額</option>
                      <option value="A1">50萬(含)以下</option>
                      <option value="A2">50萬以上~100萬(含)以下</option>
                      <option value="A3">100萬以上~500萬(含)以下</option>
                      <option value="A4">500萬以上</option>
                    </select>
                  </div>
                </div>

                <div>
                  <div class="uk-form-controls">
                    <select
                      class="uk-select"
                      id="category"
                      name="category"
                      v-model="formCategory.value"
                    >
                      <option value="0">申請種類</option>
                      <option value="C1">汽車</option>
                      <option value="C2">機車</option>
                      <option value="C3">房屋</option>
                      <option value="C4">萬物貸</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label" for="form-stacked-text"
                >內容<small class="uk-text-danger">*</small></label
              >
              <div class="uk-form-controls">
                <textarea
                  class="uk-textarea"
                  rows="5"
                  id="content"
                  name="content"
                  placeholder="Textarea"
                  v-model="formContent.value"
                  v-bind:class="{ 'uk-form-danger': formContent.error }"
                  @focus="formContent.error = false"
                ></textarea>
              </div>
            </div>
            <div v-if="errorMessage.length" class="uk-width-1-1">
              <ul class="uk-nav">
                <li
                  v-for="(error, index) in errorMessage"
                  :key="index"
                  class="uk-text-danger uk-text-center"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="success" class="uk-width-1-1">
              <p class="uk-text-success uk-text-center">{{ success }}</p>
            </div>
            <div class="uk-margin uk-text-center">
              <vue-recaptcha
                sitekey="6Lf6XI0bAAAAAExux7spROFfimzAUqoUkflQuwJz"
              >
              <input
                class="uk-button uk-button-primary uk-width-1-4@m uk-width-1-1"
                @click="addSimpleContact"
                :disabled="success"
                type="button"
                value="送出"
              />
              </vue-recaptcha>
              
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import Banner from "@/components/Banner.vue";
import { db } from "@/main";
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: "Contact",
  metaInfo: {
    title: "聯絡我們",
  },
  components: {
    Banner,
    VueRecaptcha
  },
  data() {
    return {
      errorMessage: [],
      success: null,
      formCategory: {
        value: "0",
        error: false,
      },
      formAmount: {
        value: "0",
        error: false,
      },
      formContent: {
        value: null,
        error: false,
      },
      formEmail: {
        value: null,
        error: false,
      },
      formPhone: {
        value: null,
        error: false,
      },
      formName: {
        value: null,
        error: false,
      },
    };
  },

  methods: {
    addSimpleContact() {
      // error init
      this.errorMessage = [];
      this.formName.error = false;
      this.formPhone.error = false;
      this.formContent.error = false;

      // check input
      if (
        this.formName.value &&
        this.formPhone.value &&
        this.formContent.value
      ) {
        // send
        db.collection("Contact")
          .add({
            date: Date.now(),
            type: '聯絡我們',
            name: this.formName.value,
            phone: this.formPhone.value,
            email: this.formEmail.value,
            amount: this.formAmount.value,
            category: this.formCategory.value,
            content: this.formContent.value,
          })
          // success
          .then(() => {
            // this.formCategory.value = 0;
            // this.formAmount.value = 0;
            // this.formPhone.value = null;
            // this.formName.value = null;
            this.success = "聯絡表單已送出，後續會有專人與您聯絡";
          })
          // fail
          .catch(() => {
            this.errorMessage.push("資料傳送失敗");
          });
        return true;
      }

      // error
      if (!this.formName.value) {
        this.formName.error = true;
        this.errorMessage.push("姓名未輸入");
      }
      if (!this.formPhone.value) {
        this.formPhone.error = true;
        this.errorMessage.push("聯絡電話未輸入");
      }
      if (!this.formContent.value) {
        this.formContent.error = true;
        this.errorMessage.push("內容未輸入");
      }
    },
  },
};
</script>
