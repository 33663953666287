import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';       

// components
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Offcanvas from "@/components/Offcanvas.vue";
import Float from "@/components/Float.vue";
import Loading from "@/components/Loading.vue";

// views
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Motorcycle from "@/views/Motorcycle.vue";
import Car from "@/views/Car.vue";
import House from "@/views/House.vue";
import AllBorrow from "@/views/AllBorrow.vue";

// admin views


const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    components: {
      default: Home,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    components: {
      default: About,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    components: {
      default: Contact,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/service/motor',
    name: 'Motorcycle',
    // component: () => import(/* webpackChunkName: "motorcycle" */ '../views/Motorcycle.vue')
    components: {
      default: Motorcycle,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/service/car',
    name: 'Car',
    // component: () => import(/* webpackChunkName: "car" */ '../views/Car.vue')
    components: {
      default: Car,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/service/house',
    name: 'House',
    // component: () => import(/* webpackChunkName: "house" */ '../views/House.vue')
    components: {
      default: House,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/service/allborrow',
    name: 'AllBorrow',
    // component: () => import(/* webpackChunkName: "allborrow" */ '../views/AllBorrow.vue')
    components: {
      default: AllBorrow,
      header: Header,
      footer: Footer,
      offcanvas: Offcanvas,
      float: Float,
      loading: Loading
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "*",
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(userAuth => {
    if (userAuth) {
      // console.log(userAuth)
    } else {
      if (to.matched.some(record => record.meta.authRequired)) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        })
      } else {
        next()
      }
    }
  })
  next()
})

export default router
