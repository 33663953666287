<template>
  <section>
    <div class="uk-cover-container uk-position-relative uk-visible-toggle">
      <canvas height="480px"></canvas>
      <!-- <div
        class="
          banner-bg
          uk-position-cover
          uk-animation-kenburns
          uk-animation-reverse
          uk-transform-origin-center-right
        "
      > -->
      <div
        class="
          banner-bg
          uk-position-cover
        "
      >
        <!-- <img :src="require(`@/assets/${image}`)" uk-cover /> -->
        <img v-lazy="require(`@/assets/${image}`)" uk-cover />
      </div>
      <div class="uk-position-center uk-text-center uk-width-medium uk-light">
        <h2 class="uk-margin-small-bottom">{{title}}</h2>
        <h5 class="uk-margin-small-top">{{sub}}</h5>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    sub: String,
    image: String,
  },
};
</script>