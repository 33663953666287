<template>
  <section class="uk-section uk-section-muted tm-section-primaryBackground">
    <div class="uk-margin-medium uk-text-center">
      <h3 class="uk-margin-remove">常見問題</h3>
      <hr class="uk-divider-small uk-margin-small" />
    </div>
    <div class="uk-container uk-container-small">
      <ul uk-accordion="">
        <li v-for="(item, index) in items" :key="index">
          <a class="uk-accordion-title" href="#">{{ item.question }}</a>
          <div class="uk-accordion-content">
            <p v-html="item.answer">
              {{ item.answer }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>


<script>
export default {
  name: 'Faq',
  /**
   * FAQ
   * @constructor
   * @param {string} question - 問題.
   * @param {string} answer - 答案.
   */
  props: {
    items: Array,
  },
}
</script>
