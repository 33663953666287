<template>
  <div id="about">
    <Banner title="關於我們" sub="About" image="banner-bus.jpg" />
    <section class="uk-section">
      <div class="uk-container uk-container-large">
        <div class="uk-text-center uk-margin-medium">
          <h3 class="uk-margin-remove">關於容昇</h3>
          <hr class="uk-divider-small uk-margin-small" />
        </div>
        <div class="uk-card uk-card-body uk-background-muted">
          <div class="uk-child-width-1-2@m" uk-grid>
            <div class="uk-flex uk-flex-middle">
              <img src="@/assets/about-img.jpg" />
            </div>
            <div class="uk-inline">
              <h4>20年以上的銀行專業資歷團隊</h4>
              <p>
                容昇貸款顧問是代理各大銀行及融資公司的理財顧問公司，非坊間代辦公司，案件過件前後皆無收取任何開辦費、代辦費，以銀行體系出身的顧問，為公司基本組織架構，提供專業迅速的高品質服務，成為您財務上理財規劃師。
                我們的使命在於透過我們獨特銀行專業度，結合各產業的專業知識，以關懷客戶為起點，從宏觀的格局整合財務及信用管理，為您設計專屬的全方位財務規劃！<br />
                <br />
                俗話說一文錢逼死英雄漢，無論你在哪個產業，當人生旅途遇到急用資金、理財規劃、資金周轉等問題時，會尋求專業的理財顧問，但是坊間的代辦公司，良莠不齊，有的甚至會趁火打劫，收取高額代辦費。
                容昇貸款顧問由銀行產業菁英所匯聚而成，專業迅速的高品質服務，案件過件前後皆無收取任何開辦費、代辦費，讓容昇貸款顧問團隊成為您財務上的智囊團。
              </p>
            </div>
          </div>
        </div>

        <div class="uk-text-center uk-margin-medium">
          <h3 class="uk-margin-remove">服務企業</h3>
          <hr class="uk-divider-small uk-margin-small" />
        </div>
        <div class="uk-card uk-card-body uk-background-muted">
          <div class="uk-child-width-1-2@m" uk-grid>
            <div class="uk-flex uk-flex-middle">
              <div class="uk-inline">
                <h4>容昇貸款顧問</h4>
                <p>
                  成立以來，強調『關懷』與『同理心』為服務客戶的核心價值，重視經驗傳承，強調專業創新並發揮團隊合作，一路秉持『誠信、專業、快速』的精神感動每一位客戶，站在客戶的角度出發，讓您花最少的時間和成本得到最完整的諮詢，奉行法律上的相關規定，恪守借款人的所有權益。企業為裕融、薪鑫、裕富、和潤、中租、和灣、遠信等多家上市融資公司，汽車、機車金融分期貸款業務發展優良策略夥伴。我們致力達成客戶完整金融服務，以專業知識及多元金融商品。
                  <br />滿足客戶需求並提供以下金融商品：
                </p>
                <ul class="uk-list uk-list-decimal">
                  <li>中古汽車購車貸款。(車行或私人買賣均可)。</li>
                  <li>汽車原車融資貸款。</li>
                  <li>汽車融資本行借新還舊(降息或增貸)。</li>
                  <li>汽車融資他行代償(降息或增貸)。</li>
                  <li>15年以上老爺車融資貸款(最高30萬，48期)。</li>
                  <li>二順位房屋貸款(最高500萬，120期)。</li>
                  <li>50cc以上~250cc摩托車小額融資(最高30萬，50期)。</li>
                  <li>
                    小額分期融資貸款(最高30萬，36期)。 9.上班族個人信用貸款。
                  </li>
                </ul>
                <p>
                  容昇貸款顧問結合各產業的專業知識，以關懷客戶為起點，從宏觀的格局整合財務及信用管理，為您設計專屬的全方位財務規劃！
                </p>
              </div>
            </div>
            <div>
              <img src="@/assets/about-img2.jpg" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
export default {
  metaInfo: {
    title: '關於我們',
  },
  components: {
    Banner,
  },
}
</script>