<template>
  <div id="join" class="uk-flex uk-flex-center section-join">
    <div class="uk-width-2-3@m uk-margin-auto-vertical">
      <div class="uk-card uk-card-small uk-card-default uk-card-body">
        <div class="uk-margin uk-text-center">
          <h3 class="uk-margin-remove">專人聯絡</h3>
          <hr class="uk-divider-small uk-margin-small" />
        </div>
        <div class="uk-card-body">
          <form class="uk-form-stacked">
            <div class="uk-grid-small uk-child-width-1-2@m" uk-grid>
              <div>
                <div class="uk-form-controls">
                  <input
                    placeholder="姓名"
                    class="uk-input"
                    type="text"
                    id="name"
                    name="name"
                    v-model="formName.value"
                    v-bind:class="{ 'uk-form-danger': formName.error }"
                    @focus="formName.error = false"
                  />
                  <hr>
                </div>
              </div>

              <div>
                <div class="uk-form-controls">
                  <input
                    placeholder="聯絡電話"
                    class="uk-input"
                    type="tel"
                    id="phone"
                    name="phone"
                    v-model="formPhone.value"
                    v-bind:class="{ 'uk-form-danger': formPhone.error }"
                    @focus="formPhone.error = false"
                  />
                  <hr>
                </div>
              </div>

              <div>
                <div class="uk-form-controls">
                  <select
                    class="uk-select"
                    id="amount"
                    name="amount"
                    v-model="formAmount.value"
                    v-bind:class="{ 'uk-form-danger': formAmount.error }"
                    @focus="formAmount.error = false"
                  >
                    <option value="0">融資金額</option>
                    <option value="A1">50萬(含)以下</option>
                    <option value="A2">50萬以上~100萬(含)以下</option>
                    <option value="A3">100萬以上~500萬(含)以下</option>
                    <option value="A4">500萬以上</option>
                  </select>
                  <hr>
                </div>
              </div>

              <div>
                <div class="uk-form-controls">
                  <select
                    class="uk-select"
                    id="category"
                    name="category"
                    v-model="formCategory.value"
                    v-bind:class="{ 'uk-form-danger': formCategory.error }"
                    @focus="formCategory.error = false"
                  >
                    <option value="0">申請種類</option>
                    <option value="C1">汽車</option>
                    <option value="C2">機車</option>
                    <option value="C3">房屋</option>
                    <option value="C4">萬物貸</option>
                  </select>
                  <hr>
                </div>
              </div>
              <div v-if="errorMessage.length" class="uk-width-1-1">
                <ul class="uk-nav">
                  <li
                    v-for="(error, index) in errorMessage"
                    :key="index"
                    class="uk-text-danger uk-text-center"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div v-if="success" class="uk-width-1-1">
                <p class="uk-text-success uk-text-center">{{ success }}</p>
              </div>
              <div class="uk-width-1-1 uk-text-center">
                <vue-recaptcha
                  sitekey="6Lf6XI0bAAAAAExux7spROFfimzAUqoUkflQuwJz"
                >
                  <input
                    class="
                      uk-button uk-button-primary uk-width-medium@m uk-width-1-1
                    "
                    @click="addSimpleContact"
                    :disabled="success"
                    type="button"
                    value="請專人與我聯絡"
                  />
                </vue-recaptcha>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "@/main";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Join",
  components: {
    VueRecaptcha,
  },
  props: {
    type: String,
  },
  data() {
    return {
      errorMessage: [],
      success: null,
      formCategory: {
        value: this.type,
        error: false,
      },
      formAmount: {
        value: "0",
        error: false,
      },
      formPhone: {
        value: null,
        error: false,
      },
      formName: {
        value: null,
        error: false,
      },
    };
  },
  //   firestore: {
  //     SimpleContact: db.collection("jung-sheng").orderBy("name"),
  //   },
  methods: {
    addSimpleContact() {
      // error init
      this.errorMessage = [];
      this.formName.error = false;
      this.formPhone.error = false;
      this.formAmount.error = false;
      this.formCategory.error = false;

      // check input
      if (
        this.formName.value &&
        this.formPhone.value &&
        this.formAmount.value !== "0" &&
        this.formCategory.value !== "0"
      ) {
        // send
        db.collection("Contact")
          .add({
            date: Date.now(),
            type: "專人聯絡",
            name: this.formName.value,
            phone: this.formPhone.value,
            amount: this.formAmount.value,
            category: this.formCategory.value,
          })
          // success
          .then(() => {
            // this.formCategory.value = 0;
            // this.formAmount.value = 0;
            // this.formPhone.value = null;
            // this.formName.value = null;
            this.success = "資料已送出，後續會有專人與您聯絡";
          })
          // fail
          .catch(() => {
            this.errorMessage.push("資料傳送失敗");
          });
        return true;
      }

      // error
      if (!this.formName.value) {
        this.formName.error = true;
        this.errorMessage.push("姓名未輸入");
      }
      if (!this.formPhone.value) {
        this.formPhone.error = true;
        this.errorMessage.push("聯絡電話未輸入");
      }
      if (this.formAmount.value == "0") {
        this.formAmount.error = true;
        this.errorMessage.push("融資金額未選擇");
      }
      if (this.formCategory.value == "0") {
        this.formCategory.error = true;
        this.errorMessage.push("申請種類未選擇");
      }
    },
  },
};
</script>

<style>
#home .section-join {
  margin-top: 35px;
}
#join .uk-input,
#join .uk-select {
  border: none;
}
#join .uk-form-controls > hr{
  margin: 0;
  border-top: 1px solid var(--primaryChange);
}
</style>