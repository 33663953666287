<template>
  <div id="app">
    <router-view name="loading"></router-view>
    <router-view name="header"></router-view>
    <router-view name="default"></router-view>
    <router-view name="footer"></router-view>
    <router-view name="offcanvas"></router-view>
    <router-view name="float"></router-view>
  </div>
</template>


<script>
export default {
  metaInfo: {
    // title: "Default App Title",
    titleTemplate: "%s | 容昇貸款顧問",
    htmlAttrs: {
      lang: "zh-TW",
    },
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content: "容昇借貸顧問為專業授權經銷商，有感於「銀行審核嚴格」、「民間貸款利息高」和「金融資訊不對稱」，認為貸款應有銀行的安心撥貸，也有民間的寬鬆審核，讓客戶透過我們的金融專業，真正幫助到有資金需求的人，透過貸款順利取得資金！",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "桃園借貸,桃園市借貸,平鎮借貸,桃園汽車貸款,桃園機車貸款,桃園房屋貸款,桃園房屋土地貸款,快速貸款,高額度貸款,低利率貸款",
      },
      {
        property: 'og:title',
        content: "容昇貸款顧問",
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: "容昇借貸顧問為專業授權經銷商，有感於「銀行審核嚴格」、「民間貸款利息高」和「金融資訊不對稱」，認為貸款應有銀行的安心撥貸，也有民間的寬鬆審核，讓客戶透過我們的金融專業，真正幫助到有資金需求的人，透過貸款順利取得資金！",
        vmid: 'og:description'
      },
      {
        property: 'og:image',
        content: "/favicon.ico",
        vmid: 'og:image'
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
    link: [{ rel: "favicon", href: "/favicon.ico" }],
    script: [
      {
        src: "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit",
        async: true,
        defer: true,
      },
    ],
  },
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/css/uikit.min.css";
@import "./assets/style.css";
.grecaptcha-badge {
  visibility: hidden;
}
</style>
