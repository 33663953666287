<template>
  <div id="motor">
    <Banner title="" sub="" image="banner-motor.jpg" />
    <section class="uk-section uk-background-muted">
      <div class="uk-container uk-container-large">
        <div class="uk-margin-medium uk-text-center">
          <h3 class="uk-margin-remove">機車貸款</h3>
          <hr class="uk-divider-small uk-margin-small" />
          <h3 class="uk-margin-remove">八大優勢</h3>
        </div>
        <div class="uk-margin">
          <div uk-slider="autoplay: true">
            <ul
              class="
                uk-slider-items uk-child-width-1-3 uk-child-width-1-6@m uk-grid
              "
            >
              <li
                v-for="(item, index) in MAINitems"
                :key="index"
                class="uk-text-center"
              >
                <img width="72" v-lazy="require(`@/assets/moto/${index}.png`)" />
                <h5 class="uk-margin uk-text-center">{{ item.title }}</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="uk-section">
      <div class="uk-container uk-container-large">
        <div class="uk-margin-medium uk-text-center">
          <h3 class="uk-margin-remove">申請條件</h3>
          <hr class="uk-divider-small uk-margin-small" />
        </div>
        <div class="uk-flex-center uk-flex uk-margin">
          <div class="uk-width-auto@m">
            <ul class="uk-list uk-list-decimal">
              <li>正職工作滿半年 (勞保/薪轉證明)。</li>
              <li>營運正常之自營商具營業登記設立一年(含)以上。</li>
              <li>提供親屬保人(二等親)。</li>
              <li>保證人有正職工作滿六個月以上，須提供薪轉。</li>
              <li>曾為本行分期繳款客戶，須正常繳滿六期以上，無逾期紀錄。</li>
              <li>申請人為國營事業人員或是軍公教人員。</li>
              <li>信用卡使用正常無逾期。</li>
              <li>車輛持有滿半年。</li>
            </ul>
            <p class="uk-text-center uk-text-bolder uk-text-secondary">
              以上條件符合一項即可申辦
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="uk-child-width-1-2@m uk-grid-collapse" uk-grid>
        <div class="uk-background-muted uk-padding-large">
          <div class="uk-margin-medium uk-text-center">
            <h3 class="uk-margin-remove">產品資訊</h3>
            <hr class="uk-divider-small uk-margin-small" />
          </div>
          <div class="uk-flex-center uk-flex uk-margin">
            <ul class="uk-list uk-nav">
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >專案類別:
                </label>
                優加, 優質, 一般
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >額度(萬):
                </label>
                30萬 不上銀行聯徵
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary">期數: </label>
                12-50期內
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >車齡限制:
                </label>
                8年以內(2012年後出廠)
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >承辦年齡限制:
                </label>
                20~65歲
              </li>
              <li>
                <label class="uk-text-bolder uk-text-secondary"
                  >動保設定費用:
                </label>
                5000元(外縣市需加收車馬費)
              </li>
            </ul>
          </div>
        </div>
        <div class="uk-background-primary uk-padding-large uk-light">
          <div class="uk-margin-medium uk-text-center">
            <h3 class="uk-margin-remove">準備資料</h3>
            <hr class="uk-divider-small uk-margin-small" />
          </div>
          <div class="uk-flex-center uk-flex uk-margin">
            <ul class="uk-list uk-list-disc">
              <li>申請人 雙證影本</li>
              <li>行照影本</li>
              <li>薪轉存摺近半年往來明細</li>
              <li>撥款存摺封面</li>
              <li>勞保明細</li>
              <li>財力證明</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Faq :items="QAitems" />
    <section
      class="uk-section uk-background-cover"
      v-bind:style="{ 'background-image': 'url(' + JoinImage + ')' }"
    >
      <div class="uk-container uk-container-large">
        <Join type="C2" />
      </div>
    </section>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import Join from '@/components/Join.vue'
import Faq from '@/components/Faq.vue'
export default {
  name: 'Motor',
  metaInfo: {
    title: '機車',
  },
  components: {
    Banner,
    Join,
    Faq,
  },
  data() {
    return {
      JoinImage: require('@/assets/banner-contact.jpg'),
      MAINitems: [
        {
          title: '過件率高',
          sub: '',
        },
        {
          title: '免留車',
          sub: '',
        },
        {
          title: '免看信評',
          sub: '',
        },
        {
          title: '免押證件',
          sub: '',
        },
        {
          title: '免代辦費',
          sub: '',
        },
        {
          title: '不限職業',
          sub: '',
        },
        {
          title: '不限車款廠牌',
          sub: '',
        },
        {
          title: '上市公司撥款',
          sub: '',
        },
      ],
      QAitems: [
        {
          question: '容昇貸款顧問-機車貸款會收哪些費用？',
          answer:
            '估價和申請過程無須任何費用，對保時需繳交一筆動保設定費NTD 4500元，全台機車貸款皆會收此費用，其它管道甚至收更多，好事貸最便宜。',
        },
        {
          question:
            '容昇貸款顧問-機車貸款/借款審核需多久時間？核准後多久撥款？',
          answer:
            '最快2小時可核准，核准後，最快隔日可撥款，火速解決您的資金需求。',
        },
        {
          question: '我的機車還有貸款，能幫我代償且提高額度嗎？',
          answer:
            '可以，只要您機車貸款前貸繳滿6期，我們會將您的前貸代償結清，剩餘款項直接撥入您的帳戶。',
        },
        {
          question: '申請機車貸款/借款後，車子可以繼續使用嗎？',
          answer:
            '免留車！您的機車貸款核準後，愛車無需交由銀行保管，一樣可自由使用，我們會向監理單位申請設定您的機車債權，在車貸未結清之前，無法買賣跟過戶。',
        },
        {
          question: '我無法提供收入證明或工作證明，也可辦嗎？',
          answer: '可以，有機車、有收入來源，即可提出申請。',
        },
        {
          question: '以上都沒有我的問題，怎麼辦？',
          answer: '手機可直撥0917-601-688 黃副理 或 加LINE詢問',
        },
      ],
    }
  },
}
</script>

 