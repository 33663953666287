<template>
  <div
    id="Float"
    class="uk-position-bottom-right uk-position-fixed uk-position-medium"
  >
    <div id="FACEBOOK_" class="tm-faecbook-box uk-background-default">
      <a href="https://www.facebook.com/jack601688/" target="_blank">
        <img :src="require(`@/assets/icons/facebook-box.svg`)" alt="FACEBOOK" />
      </a>
    </div>
    <div
      id="LINE_"
      class="tm-line-box uk-margin-top"
      @click="lineBox"
      v-click-outside="lineBoxHidden"
    >
      <img :src="require(`@/assets/icons/line-box.svg`)" alt="LINE@" />
      <div v-show="lineBoxDisplay" class="tm-line-big">
        <div
          class="
            uk-card uk-card-small uk-card-body
            tm-card-line
            uk-light uk-text-center
          "
        >
          <h4 class="uk-card-title uk-margin-small">LINE</h4>
          <p class="uk-margin-small uk-light">歡迎加LINE詢問</p>
          <a href="https://line.me/ti/p/liaByuJ32J#~" target="_blank">
            <img :src="require(`@/assets/lineqrcode.png`)" />
          </a>
        </div>
        <div class="line-caption">
          <p class="uk-text-center uk-margin-remove">
            請先點選LINE的『加入好友』<br />
            然後再使用『行動條碼』或<br />
            點選上面『QR碼』，將我們設為好友
          </p>
        </div>
      </div>
    </div>
    <div id="TOTOP_" class="uk-margin-top" v-show="toTopButton">
      <a
        href="#"
        class="uk-button uk-button-primary uk-light tm-to-top"
        uk-totop
        uk-scroll
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toTopButton: false,
      lineBoxDisplay: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > window.innerHeight / 2) {
        this.toTopButton = true;
      } else {
        this.toTopButton = false;
      }
    },
    lineBox() {
      if (!this.lineBoxDisplay) {
        this.lineBoxDisplay = true;
      }
    },
    lineBoxHidden() {
      if (this.lineBoxDisplay) {
        this.lineBoxDisplay = false;
      }
    },
  },
};
</script>