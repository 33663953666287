import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/app';
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore

// firestore
import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

var firebaseConfig = {
  apiKey: "AIzaSyCnDdHC7kZUornMz36IRaOrs7r36t2Qdio",
  authDomain: "jung-sheng.firebaseapp.com",
  projectId: "jung-sheng",
  storageBucket: "jung-sheng.appspot.com",
  messagingSenderId: "746628240494",
  appId: "1:746628240494:web:d6cd84ca3a1cc7f80b827f",
  measurementId: "G-M0F61ZZVEJ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Vuefire db
export const db = firebase.firestore()

// UIKIT Icon
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

// Date moment
import moment from 'moment'
Vue.filter('date_format', function (value, formatStr = 'YYYY-MM-DD HH:mm') {
  return moment(value).format(formatStr)
})

// VueMeta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// v-click-outside
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

// VueLazyload
import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload)
// or with options
Vue.use(VueLazyload, {
  // preLoad: 1,
  // error: 'dist/error.png',
  loading: require('./assets/loading-spin.svg'),
  // attempt: 1
})
 

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
