<template>
  <section class="uk-section">
    <div class="uk-container uk-container-large">
      <div class="uk-margin-medium uk-text-center">
        <h3 class="uk-margin-remove">服務項目</h3>
        <hr class="uk-divider-small uk-margin-small" />
      </div>
      <div
        id="service"
        class="uk-child-width-1-4@l uk-child-width-1-2@s"
        uk-grid
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="uk-flex uk-flex-center"
        >
        <router-link :to="item.path">
            <div class="uk-inline-clip uk-transition-toggle uk-light">
              <div
                class="
                  uk-cover-container uk-transition-scale-up uk-transition-opaque
                  server-bg
                "
              >
                <canvas width="500px" height="500px"></canvas>
                <img v-lazy="item.image" uk-cover />
              </div>
              <div class="uk-position-bottom uk-position-medium">
                <h3 class="uk-margin-remove">
                  {{ item.title }} <small class="">{{ item.sub }}</small>
                </h3>
              </div>
            </div>
          </router-link>
        </div>
        <!-- <div v-for="(item, index) in items" :key="index">
          <div class="uk-card uk-card-default uk-card-hover">
            <div class="uk-card-media-top">
              <div
                class="
                  uk-flex uk-flex-center uk-flex-middle uk-background-muted
                "
              >
                <img v-lazy="item.image" width="128" height="128" />
              </div>
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-title">{{ item.title }}</h3>
              <ul class="uk-list uk-list-disc">
                <li v-for="(item_, index) in item.list" :key="index">
                  {{ item_ }}
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Service",
  /**
   * Service
   * @constructor
   * @param {string} image - 圖片.
   * @param {string} title - 標題.
   * @param {string} sub - 副標.
   * @param {string} list - 條列.
   */

  data: function () {
    return {
      items: [
        {
          image: require("@/assets/banner-car.jpg"),
          title: "汽車貸款",
          sub: "Car",
          list: ["10萬~200萬內", "12-60期內", "車齡+貸款期限 小於19年"],
          path: "/service/car"
        },
        {
          image: require("@/assets/banner-motor.jpg"),
          title: "機車貸款",
          sub: "Motor",
          list: ["30萬", "12~~50期", "8年以內(2012年後出廠)"],
          path: "/service/motor"
        },
        {
          image: require("@/assets/banner-house.jpg"),
          title: "房屋貸款",
          sub: "House",
          list: ["10萬~300萬", "24期~120期", "借款人需年齡22~65歲"],
          path: "/service/house"
        },
        {
          image: require("@/assets/banner-all.jpg"),
          title: "萬物貸",
          sub: "AllBorrow",
          list: ["年滿20歲", "非融資公司黑名單", "公司保滿半年即可"],
          path: "/service/allborrow"
        },
      ],
    };
  },
};
</script>
<style>
/* #service .uk-card-media-top > div {
  height: 200px;
} */
#service a > div {
  border-radius: 10px;
}
</style>
