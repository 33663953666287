<template>
  <section
    id="slideshow"
    class="uk-position-relative uk-visible-toggle"
    tabindex="-1"
    uk-slideshow="ratio: 1920:700; min-height: 400; autoplay: false; autoplay-interval: 8000"
  >
    <ul class="uk-slideshow-items">
      <li v-for="(item, index) in items" :key="index">
        <div class="banner-bg">
          <img :src="item.image" uk-cover />
        </div>
        <div class="uk-position-center uk-position-z-index">
          <div class="uk-width slong-width uk-text-center" :class="item.position">
            <h1
              v-if="item.slogn"
              class="uk-margin-small uk-light"
              style="user-select: none"
            >
              {{ item.slogn }}
            </h1>
            <h3
              v-if="item.description"
              class="uk-margin-remove-top uk-light"
              style="user-select: none"
            >
              {{ item.description }}
            </h3>
            <div v-if="item.buttonText" class="uk-margin">
              <router-link
                class="uk-button uk-button-primary uk-width-small"
                :to="item.path"
              >
                {{ item.buttonText }}
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div v-if="item.extra" :class="item.extra.class" class="uk-position-z-index">
          <img :src="item.extra.image" />
        </div> -->
      </li>
    </ul>
    <a
      class="
        uk-position-center-left uk-position-z-index uk-position-small uk-light
      "
      href="#"
      uk-slidenav-previous
      uk-slideshow-item="previous"
    ></a>
    <a
      class="
        uk-position-center-right uk-position-z-index uk-position-small uk-light
      "
      href="#"
      uk-slidenav-next
      uk-slideshow-item="next"
    ></a>
  </section>
</template>

<script>
export default {
  name: 'Slideshow',
  /**
   * Slideshow
   * @constructor
   * @param {string} image - 圖片.
   * @param {string} slogn - 標語.
   * @param {string} description - 敘述.
   * @param {string} buttonText - 按鈕文字.
   * @param {string} path - 連結.
   */
  data: function () {
    return {
      items: [
        {
          image: require('@/assets/banner-home3.jpg'),
          slogn: '容昇貸款',
          description: '急需資金，容昇幫您',
          buttonText: '立即前往',
          position: 'uk-text-left@s',
          path: '/service/house',
        },
        {
          image: require('@/assets/banner-home5.jpg'),
          slogn: '容昇房屋貸款',
          description: '安心撥款，親民審核',
          buttonText: '立即前往',
          position: 'uk-text-right@s',
          path: '/service/house',
        },

        // {
        //   image: require("@/assets/banner-home4.jpg"),
        //   slogn: "容昇汽機車貸款",
        //   description: "效率最快，額度最高，利率最低",
        //   buttonText: "",
        //   path: "#",
        // },
        {
          image: require('@/assets/banner-home2.jpg'),
          slogn: '容昇汽機車貸款',
          description: '效率最快，額度最高，利率最低',
          buttonText: '立即前往',
          position: 'uk-text-right@s slong-moto',
          path: '/service/car',
        },
        {
          image: require('@/assets/banner-home.jpg'),
          slogn: '容昇貸款顧問',
          description: '急需資金，容昇幫您',
          buttonText: '立即前往',
          position: 'uk-text-left@s',
          path: '/about',
          // extra: {
          //   class: 'uk-position-bottom-right',
          //   image: require("@/assets/slong/home-slong-2.png"),
          // }
        },
      ],
    }
  },
}
</script>

<style>
.background-img:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url('../assets/dots.png');
  -webkit-background-size: 5px 5px;
  -o-background-size: 5px 5px;
  background-size: 5px 5px;
}
#slideshow h1,
#slideshow h3 {
  font-weight: bold;
  letter-spacing: 3px;
}
.slong-moto {
  margin-top: -70px;
}
.slong-width {
  width: 1000px;
}
</style>